<div class="d-flex justify-content-center align-items-center text-center bg-s0 page-not-found">
  <div class="page-not-found-content">
    <div class="not-found-icon">
      <app-image [src]="'./assets/images/lazyPolygon.svg'" [width]="'100%'" [height]="'100%'"></app-image>
    </div>
    <div>
      <app-text [class]="'text-c300 h2-bold'">There is nothing here...</app-text>
    </div>
    <div class="not-found-wrap">
      <app-text [class]="'text-c100'">
        We can’t find the Badges you’re looking for.
      </app-text>
      <app-text [class]="'text-c100'">
        Check the URL, or head back Dashboard.
      </app-text>
    </div>
    <div class="d-flex justify-content-center">
      <app-link [class]="'bg-s20 custom-link'" [url]="dashboardUrl">
        <span class="chervon_left_icon">
          <app-image [src]="'./assets/images/chevron_left.svg'"></app-image>
        </span>
        <span class="icon-wrap">
          <app-image [src]="'./assets/images/dashboard.svg'"></app-image>
        </span>
        <app-text class="body-l text-c100">Dashboard</app-text>
      </app-link>
    </div>
  </div>
  <div class="page-not-found-image">
    <app-image [src]="'./assets/images/page_not_found.png'" [width]="'100%'" [height]="'100%'"></app-image>
  </div>
</div>
