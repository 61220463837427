<img
  *ngIf="class != 'badge-item' && class != 'thumbnail'"
  [src]="src"
  [alt]="alt"
  [ngStyle]="{
    'width': width? width: 'auto' ,
    'height': height? height: 'auto',
    'max-width' : maxWidth ? maxWidth : '100%',
    'border-radius': borderRadius ? borderRadius : ''
  }"
  [class]="class"
  (load)="imageLoad()"
/>

<img
  *ngIf="class == 'badge-item' || class == 'thumbnail'"
  [alt]="alt"
  [ngStyle]="{
    'border-radius': borderRadius ? borderRadius : ''
  }"
  [class]="class"
  [defaultImage]="defaultImage"
  [lazyLoad]="src"
  [useSrcset]="true"
/>
