import {BadgesModel} from "../../../models/badges.model";
import {BadgeDetailsModel} from "../../../models/badge-details.model";
import { UserInfoModel } from "src/app/models/user-info.model";
import {ItemMapper} from "../../../lib/item-mapper";

export class BadgesMapper extends ItemMapper<BadgesModel>{
  protected mapFromItem(item):BadgesModel{
    const badgesModel = new BadgesModel();
    const userInfoModel = new UserInfoModel()
    userInfoModel.username = item.username;
    userInfoModel.firstName = item.firstName;
    userInfoModel.lastName = item.lastName;
    userInfoModel.badgeCount = item.badges.length;
    badgesModel.userInfo = userInfoModel;
    if(item.badges.length > 0){
      item.badges.map(data => {
        const badgeDetailsModel = new BadgeDetailsModel();
        badgeDetailsModel.id = data.id;
        badgeDetailsModel.badgeID = data.badgeID;
        badgeDetailsModel.badgeName = data.name;
        badgeDetailsModel.badgeIconImage = data.badgeIconImage;
        badgeDetailsModel.earnedDate = data.earnedDate;
        badgeDetailsModel.level = data.level;
        badgesModel.badges.push(badgeDetailsModel);
      })
    }
    return badgesModel;
  }
}
