<div class="d-flex justify-content-center align-items-center empty-data-wrap bg-s20">
  <div>
    <div class="empty-image-wrap">
      <app-image [src]="'./assets/images/badge-empty.svg'"></app-image>
    </div>
    <div class="no-badge text-center">
      <app-text class="text-s70 h2-bold">
        No badges yet.
      </app-text>
    </div>
    <app-text class="text-s70 h4-light">
      Complete activities to earn the first badge.
    </app-text>
  </div>
</div>
