<div class="badge-header">
  <div class="badge-user">
    <!-- <app-text class="h2-strong">{{ badgeInfo?.username }} </app-text> -->
    <app-text *ngIf="badgeInfo?.badgeCount > 0" class="h2-normal text-c300">
      <span class="h2-strong">{{ badgeInfo?.username }}</span> &nbsp;has earned
      <span class="h2-strong badge-count">{{ badgeInfo?.badgeCount }}</span>
      {{ badgeInfo?.badgeCount > 1 ? "badges" : "badge" }}</app-text
    >
    <app-text *ngIf="badgeInfo?.badgeCount == 0" class="h2-normal test-c300"
      ><span class="h2-strong">{{ badgeInfo?.username }}</span> &nbsp;has no
      badges yet</app-text
    >
  </div>

  <div class="bar bg-s40"></div>

  <div class="action-btn">
    <div class="d-flex" *ngIf="badgeInfo?.badgeCount > 0">
      <app-text
        style="margin-right: 8px"
        [class]="'text-c300 light-body-m'"
        class="sort-by"
      >
        Sort by
      </app-text>
      <div style="position: relative">
        <div class="lesson" (click)="clickSort()">
          <app-text [class]="'text-c300 lesson light-body-m'">{{
            isLesson ? "Lesson" : "Date"
          }}</app-text>
          <app-image
            [src]="'./assets/images/down.svg'"
            [class]="'lesson'"
          ></app-image>
        </div>
        <app-custom-dropdown [type]="'sort'" *ngIf="showSort">
          <app-custom-select
            [isLesson]="isLesson"
            (outputOption)="sortOption($event)"
          ></app-custom-select>
        </app-custom-dropdown>
      </div>
    </div>

    <div style="position: relative">
      <div class="social" (click)="clickSocial()">
        <app-text [class]="'text-y300 social light-body-m'">Share</app-text>
        <app-image
          [src]="'./assets/images/share.svg'"
          [class]="'social'"
        ></app-image>
      </div>
      <app-custom-dropdown [type]="'social'" *ngIf="showSocial">
        <app-social-button (clickOut)="clickedOut()"></app-social-button>
      </app-custom-dropdown>
    </div>
  </div>
</div>
