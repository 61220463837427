<div class="badge-list-wrap">
  <ng-container *ngIf="!loading">
    <div *ngFor="let badge of badges">
      <app-badge-item [badge]="badge"></app-badge-item>
    </div>
  </ng-container>
  <ng-container *ngIf="loading">
    <div class="loader" style="min-height: 100px"></div>
  </ng-container>
</div>
