import {Inject, Injectable} from '@angular/core';
import {DataRequirements, DataRequirementsInjectionToken} from '../data.requirements';
import { BadgesMapper } from 'src/app/home/business-logic/mappers/badges.mapper';

@Injectable({
    providedIn: 'root'
})
export class BadgesUseCase {
    constructor(
        private mapper: BadgesMapper,
        @Inject(DataRequirementsInjectionToken) private data: DataRequirements
    ) {}

    run(username) {
      return this.data.getBadges(username).pipe(this.mapper.map);
    }
}
