import {DataRequirements} from '../business-logic/data.requirements';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import { BadgesService } from './api-services/badge.service';

@NgModule({
    imports: [HttpClientModule]
})
export class DataFacade implements DataRequirements {
    constructor(
      private badgeService: BadgesService
    ) {}

    getBadges(username){
      return this.badgeService.getBadges(username);
    }
}
