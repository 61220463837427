import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { ButtonComponent } from "./button/button.component";
import { ImageComponent } from "./image/image.component";
import { TextComponent } from "./text/text.component";
import { LazyLoadImageModule } from "ng-lazyload-image";
import { ShareModule } from "ngx-sharebuttons";
import { SocialButtonComponent } from "./social-button/social-button.component";
import { CustomDropdownComponent } from "./custom-dropdown/custom-dropdown.component";
import { CustomSelectComponent } from "./custom-select/custom-select.component";
import { LinkComponent } from "src/app/global-components/link/link.component";
import { LoaderComponent } from "src/app/global-components/loader/loader.component";
const components = [
  ButtonComponent,
  ImageComponent,
  TextComponent,
  SocialButtonComponent,
  CustomDropdownComponent,
  CustomSelectComponent,
  LinkComponent,
  LoaderComponent
];

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LazyLoadImageModule,
    ShareModule,
  ],
})
export class GlobalComponentsModule {}
