<div class="social-wrapper">
  <!-- <button shareButton="facebook" [url]="url" (click)="otherClick()">
    <app-image [src]="facebookicon" [class]="'social-icon'"></app-image>
    <app-text [class]="'light-body-m text-c300'">Facebook</app-text>
  </button> -->
  <!-- <button shareButton="twitter" [url]="url" (click)="otherClick()">
    <app-image [src]="twittericon" [class]="'social-icon'"></app-image>
    <app-text [class]="'light-body-m text-c300'">Twitter</app-text>
  </button> -->
  <a
    [href]="fbUrl"
    onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;"
    target="_blank"
    title="Share on Facebook"
  >
    <button (click)="otherClick()">
      <app-image [src]="facebookicon" [class]="'social-icon'"></app-image>
      <app-text [class]="'light-body-m text-c300'">Facebook</app-text>
    </button>
  </a>

  <a
    [href]="twitterUrl"
    onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');return false;"
    target="_blank"
    title="Share on Twitter"
  >
    <button (click)="otherClick()">
      <app-image [src]="twittericon" [class]="'social-icon'"></app-image>
      <app-text [class]="'light-body-m text-c300'">Twitter</app-text>
    </button>
  </a>
  <button
    (click)="linkClick()"
    shareButton="copy"
    [url]="url"
    class="copy-link"
    [ngClass]="{ 'copy-link-hover': changeIcon }"
  >
    <app-image
      [src]="changeIcon ? tickicon : copylinkicon"
      [class]="'social-icon copy-link'"
    ></app-image>
    <app-text [class]="'light-body-m text-c300 copy-link'">{{
      changeIcon ? "Copied" : "Copy Link"
    }}</app-text>
  </button>
</div>
