import {BusinessLogicRequirements} from '../presentation/business-logic.requirements';
import {NgModule} from '@angular/core';
import {DataRequirementsInjectionToken} from './data.requirements';
import {DataFacade} from '../data/data.facade';
import {DataRequirementsFake} from './data.requirements.fake';

import { BadgesUseCase } from 'src/app/home/business-logic/use-cases/badges.use-case';

import { BadgesMapper } from 'src/app/home/business-logic/mappers/badges.mapper';

@NgModule({
    imports: [DataFacade],

    providers: [
        {
            provide: DataRequirementsInjectionToken,
            useClass: DataFacade
            // useClass: DataRequirementsFake
        },
        BadgesMapper
    ]
})
export class BusinessLogicFacade implements BusinessLogicRequirements {
    constructor(
      private badgesUseCase: BadgesUseCase
    ) {}

    getBadges(username){
      return this.badgesUseCase.run(username);
    }
}
