import {Inject, Component} from '@angular/core';
import {BusinessLogicRequirements, BusinessRequirementsInjectionToken} from '../business-logic.requirements';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found-controller.component.html',
    styleUrls: ['./page-not-found-controller.component.css']
})
export class PageNotFoundControllerComponent {
    public dashboardUrl = environment.dashboardUrl;
    constructor(
        @Inject(BusinessRequirementsInjectionToken) private business: BusinessLogicRequirements
    ) {}
}
