<div class="badge-item-wrapper bg-s0">
  <app-image [src]="badge.badgeIconImage" [class]="'badge-item'"></app-image>
  <div class="badge-header">
    <app-text [class]="'bold-body-sm text-c300 badge-header-name'">{{
      badge.badgeName
    }}</app-text>
  </div>
  <div class="badge-footer">
    <app-text [class]="'bold-body-sm text-c300'"
      >LEVEL&nbsp;{{ badge.level }}</app-text
    >
    <app-text [class]="'header-light-xs1 text-c100'">{{ date }}</app-text>
  </div>
</div>
