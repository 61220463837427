import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";

import { environment } from "src/environments/environment";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class BadgesService {
  private baseUrl = environment.apiurl + "api/v1/";
  constructor(private http: HttpClient) {}

  getBadges(badgeParams) {
    let url =
      this.baseUrl +
      "badges?user=" +
      badgeParams.name +
      "&sortBy=" +
      badgeParams.sortBy;
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        apikey: environment.apikey,
        secretkey: environment.secretkey,
      }),
    };
    return this.http.get(url, httpOptions).pipe(
      map((res: Response) => {
        let result = [];
        result.push(res);
        return result;
      })
    );
  }
}
