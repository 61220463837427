<div class="d-flex justify-content-between align-items-center header-bar-wrap">
  <app-link [class]="'bg-pale custom-link'" [url]="dashboardUrl">
    <span class="chervon_left_icon">
      <app-image [src]="'./assets/images/chevron_left.svg'"></app-image>
    </span>
    <span class="icon-wrap">
      <app-image
        [src]="'./assets/images/dashboard.svg'"
      ></app-image>
    </span>
    <app-text class="body-l text-c100 link-text">Dashboard</app-text>
  </app-link>
  <div class="stemwerkz-logo-wrap">
    <app-image [src]="'./assets/images/STEMWerkzLogo.png'" [width]="'100%'" [height]="'100%'"></app-image>
  </div>
  <div class="d-flex align-items-center">
    <div class="badge-label">
      <app-text
        [class]="'text-s0 header-l-light m-r-16'"
        style="margin-right: 16px"
        >
        Badges
      </app-text>
    </div>
    <span class="badge-icon-wrap">
      <app-image [src]="'./assets/images/badge_icon.svg'"></app-image>
    </span>
  </div>
</div>
