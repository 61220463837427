import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GlobalComponentsModule } from "../../../global-components/global-components.module";
import { SharedComponentsModule } from "../shared-components/shared-components.module";
import { BadgesHomeControllerComponent } from "./badges-home-controller.component";
import { BadgeHeaderComponent } from "src/app/home/presentation/badges-home/components/badge-header/badge-header.component";
import { EmptyDataComponent } from "src/app/home/presentation/badges-home/components/empty-data/empty-data.component";
import { BadgeListComponent } from "src/app/home/presentation/badges-home/components/badge-list/badge-list.component";
import { RouterModule } from "@angular/router";
import { BadgeItemComponent } from "./components/badge-item/badge-item.component";

const components = [
  BadgeHeaderComponent,
  EmptyDataComponent,
  BadgeListComponent,
  BadgeItemComponent,
];

@NgModule({
  declarations: [BadgesHomeControllerComponent, components],
  exports: [BadgesHomeControllerComponent, components],
  imports: [
    CommonModule,
    RouterModule,
    GlobalComponentsModule,
    SharedComponentsModule,
  ],
})
export class BadgesHomeModule {}
