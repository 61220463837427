import { EventEmitter } from "@angular/core";
import { Component, Input, Output } from "@angular/core";
import { CustomSelectStore } from "./custom-select.store";

@Component({
  selector: "app-custom-select",
  templateUrl: "./custom-select.component.html",
  styleUrls: ["./custom-select.component.css"],
})
export class CustomSelectComponent {
  @Input() isLesson = true;

  @Output() outputOption = new EventEmitter<any>();
  public tickIcon = "/assets/images/tick_m_blue.svg";

  constructor(private store: CustomSelectStore) {}

  clickedOption(value) {
    this.outputOption.emit(value);
  }
}
