<div *ngIf="!loading" class="badges-home">
  <div class="d-flex align-items-center badges-home-header bg-s0">
    <app-badge-header
      [isLesson]="isLesson"
      [badgeInfo]="badgeInfo"
      (outputSort)="sortOption($event)"
      class="w-100"
    ></app-badge-header>
  </div>
  <div class="badges-container">
    <div
      *ngIf="badgeInfo?.badgeCount > 0 || loading"
      style="position: absolute"
      class="w-100 corner-wrapper"
    >
      <app-image [src]="corner1" [class]="'corner1'"></app-image>
      <app-image [src]="corner2" [class]="'corner2'"></app-image>
    </div>
    <div
      *ngIf="badgeInfo?.badgeCount == 0"
      style="position: absolute"
      class="w-100 corner-wrapper"
    >
      <app-image [src]="empty_corner_left" [class]="'corner1'"></app-image>
      <app-image [src]="empty_corner_right" [class]="'corner2'"></app-image>
    </div>
    <app-badge-list *ngIf="loading" [loading]="loading"></app-badge-list>

    <app-empty-data *ngIf="badgeInfo?.badgeCount == 0"></app-empty-data>
    <app-badge-list
      *ngIf="badgeInfo?.badgeCount > 0"
      [badges]="badges"
    ></app-badge-list>
  </div>
</div>
<div *ngIf="loading" class="d-flex justify-content-center align-items-center loading-wrap">
  <app-loader></app-loader>
</div>
