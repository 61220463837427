import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LinkStore {

}

export enum LinkStoreKeys {

}
