import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SocialButtonStore {

}

export enum SocialButtonStoreKeys {

}
