import {Inject, Component} from '@angular/core';
import {Router,ActivatedRoute,NavigationEnd} from '@angular/router';
import {BusinessLogicRequirements, BusinessRequirementsInjectionToken} from '../business-logic.requirements';
import {HomePageStore} from './home-page.store';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-home-page',
    templateUrl: './home-page-controller.component.html',
    styleUrls: ['./home-page-controller.component.css']
})
export class HomePageControllerComponent {
    public dashboardUrl = environment.dashboardUrl;

    constructor(
        @Inject(BusinessRequirementsInjectionToken) private business: BusinessLogicRequirements,
        private store: HomePageStore
    ) {}
}
