import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class BadgeItemStore {

}

export enum BadgeItemStoreKeys {

}
