import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomePageControllerComponent } from "./home/presentation/home-page/home-page-controller.component";
import { BadgesHomeControllerComponent } from "./home/presentation/badges-home/badges-home-controller.component";
import { PageNotFoundControllerComponent } from "./home/presentation/page-not-found/page-not-found-controller.component";

const routes: Routes = [
  {
    path: "",
    component: HomePageControllerComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'page/not-found' },
      {
        path: "page/not-found",
        component: PageNotFoundControllerComponent
      },
      {
        path: ":username",
        component: BadgesHomeControllerComponent
      }
    ]
  },
  {
    path: "**",
    redirectTo: "page/not-found"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
