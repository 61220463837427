import { Component, Input } from "@angular/core";
import * as moment from "moment";
import { BadgeItemStore } from "./badge-item.store";

@Component({
  selector: "app-badge-item",
  templateUrl: "./badge-item.component.html",
  styleUrls: ["./badge-item.component.css"],
})
export class BadgeItemComponent {
  constructor(private store: BadgeItemStore) {}
  @Input() badge;
  public date;

  ngOnInit() {
    this.date = this.transformdate(this.badge.earnedDate);
  }

  transformdate(date) {
    var month = moment(date).format("MMMM");
    var day = moment(date).format("D");
    var year = moment(date).format("YYYY");
    var returndate = day + " " + month + " " + year;
    return returndate;
  }
}
