<div class="select-wrapper">
  <div
    (click)="clickedOption(true)"
    class="option"
    [ngClass]="{ 'bg-b25': isLesson, 'test-0': !isLesson }"
  >
    <app-image [src]="tickIcon" *ngIf="isLesson" class="tick"></app-image>
    <app-text [class]="'text-c300 light-body-m'" style="margin-left: 52px"
      >Lesson</app-text
    >
  </div>
  <div
    (click)="clickedOption(false)"
    class="option"
    [ngClass]="{ 'bg-b25': !isLesson, 'test-0': isLesson }"
  >
    <app-image [src]="tickIcon" *ngIf="!isLesson" class="tick"></app-image>
    <app-text [class]="'text-c300 light-body-m'" style="margin-left: 52px"
      >Date</app-text
    >
  </div>
</div>
