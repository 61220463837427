import { Component, Input, Output } from "@angular/core";

@Component({
  selector: "app-badge-list",
  templateUrl: "./badge-list.component.html",
  styleUrls: ["./badge-list.component.css"],
})
export class BadgeListComponent {
  @Input() badges;
  @Input() loading;
}
