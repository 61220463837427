import {
  Component,
  Input,
  Output,
  HostListener,
  EventEmitter,
} from "@angular/core";
import * as $ from "jquery";
@Component({
  selector: "app-badge-header",
  templateUrl: "./badge-header.component.html",
  styleUrls: ["./badge-header.component.css"],
})
export class BadgeHeaderComponent {
  @Input() badgeInfo;
  public showSocial = false;
  public showSort = false;
  @Input() isLesson = true;

  ngOnInit() {
    console.log("isLesson ", this.isLesson);
  }

  @Output() outputSort = new EventEmitter<any>();
  clickSocial() {
    this.showSocial = !this.showSocial;
    this.showSort = false;
  }

  clickSort() {
    this.showSort = !this.showSort;
    this.showSocial = false;
  }

  sortOption(value) {
    this.isLesson = value;
    this.outputSort.emit(value);
  }

  clickedOut() {
    this.showSocial = false;
    this.showSort = false;
  }

  @HostListener("document:click", ["$event"])
  clickout(event) {
    if (
      $(event.target).hasClass("lesson") ||
      $(event.target).hasClass("social")
    ) {
      console.log("show something ");
    } else {
      setTimeout(() => {
        this.showSocial = false;
        this.showSort = false;
      }, 100);
    }
  }
}
