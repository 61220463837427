import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GlobalComponentsModule } from "../global-components/global-components.module";
import { SharedComponentsModule } from "./presentation/shared-components/shared-components.module";
import { BusinessLogicFacade } from "./business-logic/business-logic.facade";
import { BusinessRequirementsInjectionToken } from "./presentation/business-logic.requirements";
import { BusinessLogicRequirementsFake } from "./presentation/business-logic.requirements.fake";
import { HomePageModule } from "./presentation/home-page/home-page.module";
import { BadgesHomeModule } from "./presentation/badges-home/badges-home.module";
import { PageNotFoundModule } from "./presentation/page-not-found/page-not-found.module";

@NgModule({
  imports: [
    BusinessLogicFacade,
    CommonModule,
    GlobalComponentsModule,
    SharedComponentsModule,
    HomePageModule,
    BadgesHomeModule,
    PageNotFoundModule
  ],
  providers: [
    {
      provide: BusinessRequirementsInjectionToken,
      useClass: BusinessLogicFacade,
      // useClass: BusinessLogicRequirementsFake
    },
  ],
  exports: [],
})
export class HomeFeatureModule {}
