import { Inject, Component, OnInit } from "@angular/core";
import {
  BusinessLogicRequirements,
  BusinessRequirementsInjectionToken,
} from "../business-logic.requirements";
import { Router, ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: "app-badges-home",
  templateUrl: "./badges-home-controller.component.html",
  styleUrls: ["./badges-home-controller.component.css"],
})
export class BadgesHomeControllerComponent implements OnInit {
  public badgeInfo: any;
  public badges: any = [];
  public username: string;
  public encodedName: string;
  public loading = true;
  public isLesson = true;

  public corner1 = "/assets/images/corner1.png";
  public corner2 = "/assets/images/corner2.png";
  public empty_corner_left = "/assets/images/empty-corner-left.png";
  public empty_corner_right = "/assets/images/empty-corner-right.png";
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    @Inject(BusinessRequirementsInjectionToken)
    private business: BusinessLogicRequirements
  ) {}

  ngOnInit() {
    if (localStorage.getItem("isLesson"))
      this.isLesson = localStorage.getItem("isLesson") == "true" ? true : false;
    else this.isLesson = true;
    console.log(this.isLesson);
    this.route.params.subscribe((params: Params) => {
      this.username = this.customTrim(params.username);
      this.encodedName = this.encodeString(this.username);
      console.log(
        "username ~",
        this.username,
        "encodedName ~",
        this.encodedName
      );
    });
    var badgeParams = {
      name: this.encodedName,
      sortBy: this.isLesson == true ? "name" : "date",
      // sortBy: this.isLesson == true ? "lessonId" : "date",
    };
    this.getUserBadges(badgeParams);
  }

  customTrim(str) {
    return str.replace(/^\s+|\s+$/gm, "");
  }

  encodeString(string) {
    var encodedString = btoa(string);
    return encodedString;
  }

  getUserBadges(badgeParams) {
    this.loading = true;
    this.business.getBadges(badgeParams).subscribe(
      (res) => {
        this.badgeInfo = res[0].userInfo;
        this.badges = res[0].badges;
        console.log("getUserBadges", this.badgeInfo);
        this.loading = false;
      },
      (err) => {
        console.log(err);
        if (err.status == 404) {
          this.router.navigate(["/page/not-found"]);
        }
        this.loading = false;
      }
    );
  }

  sortOption(value) {
    console.log("isLesson ", value);
    var badgeParams = {
      name: this.encodedName,
      sortBy: value == true ? "name" : "date",
      // sortBy: this.isLesson == true ? "lessonId" : "date",
    };
    //call api with params
    localStorage.setItem("isLesson", value.toString());
    this.isLesson = value;
    this.getUserBadges(badgeParams);
  }
}
