import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ImageStore } from "./image.store";
declare var require: any;
@Component({
  selector: "app-image",
  templateUrl: "./image.component.html",
  styleUrls: ["./image.component.css"],
})
export class ImageComponent {
  @Input() src;
  @Input() alt = "alt";
  @Input() width;
  @Input() height;
  @Input() class = "default-class";
  @Input() maxWidth;
  @Input() borderRadius;

  @Output() imageIsLoaded = new EventEmitter<any>();

  public defaultImage = "/assets/images/badge_loading.svg";
  constructor(private store: ImageStore) {}
  ngOnInit() {}
  imageLoad() {
    this.imageIsLoaded.emit();
  }
}
