import { Component, EventEmitter, HostListener, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { SocialButtonStore } from "./social-button.store";
import * as $ from "jquery";
@Component({
  selector: "app-social-button",
  templateUrl: "./social-button.component.html",
  styleUrls: ["./social-button.component.css"],
})
export class SocialButtonComponent {
  constructor(
    private store: SocialButtonStore,
    private route: ActivatedRoute
  ) {}

  @Output() clickOut = new EventEmitter<any>();
  public facebookicon = "/assets/images/facebook.svg";
  public twittericon = "/assets/images/twitter.svg";
  public copylinkicon = "/assets/images/link.svg";
  public tickicon = "/assets/images/tick_m_gray.svg";
  public changeIcon = false;
  public url =
    "https://www.figma.com/file/exx9bPYVzRHP2rQ3MlfS7p/STEM-%26-STEAM";
  public twitterUrl = "https://twitter.com/share?url=";
  public fbUrl = "https://www.facebook.com/sharer/sharer.php?u=";
  ngOnInit() {
    console.log(window.location.href);
    // this.url = encodeURI(window.location.href);
    // this.url = "http://localhost:4201/stag%20c18";
    this.url = window.location.href;
    // this.url = encodeURI(
    //   "https://stemwerkz-badges-dev.pagewerkz.com/amk@dev9884"
    // );
    this.twitterUrl +=
      encodeURI(window.location.href) +
      "&via=STEMWerkzBadges&text=STEMWerkzBadges";
    this.fbUrl += encodeURI(window.location.href) + "&t=STEMWerkzBadges";
  }

  otherClick() {
    this.clickOut.emit();
  }

  linkClick() {
    console.log(this.url);
    this.changeIcon = true;
    setTimeout(() => {
      this.clickOut.emit();
    }, 200);
  }

  // @HostListener("document:click", ["$event"])
  // clickout(event) {
  //   if (
  //     $(event.target).hasClass("social") &&
  //     ($(event.target).hasClass("copy-link") ||
  //       $(event.target).hasClass("'copy-link'"))
  //   ) {
  //     console.log("change icon ");
  //     this.changeIcon = true;
  //     setTimeout(() => {
  //       this.clickOut.emit();
  //     }, 200);
  //   } else {
  //     console.log($(event.target)[0].classList);
  //     //emit
  //     this.clickOut.emit();
  //   }
  // }
}
