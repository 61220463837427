import {Component,Input} from '@angular/core';
import { LinkStore } from 'src/app/global-components/link/link.store';

@Component({
    selector: 'app-link',
    templateUrl: './link.component.html',
    styleUrls: ['./link.component.css']
})
export class LinkComponent {
  @Input() url;
  @Input() class;

  constructor(private store:LinkStore){

  }
}
