import { Component, Input } from "@angular/core";
import { CustomDropdownStore } from "./custom-dropdown.store";

@Component({
  selector: "app-custom-dropdown",
  templateUrl: "./custom-dropdown.component.html",
  styleUrls: ["./custom-dropdown.component.css"],
})
export class CustomDropdownComponent {
  @Input() type;
  constructor(private store: CustomDropdownStore) {}

  ngOnInit() {
    if (this.type == "social") {
      document.getElementById("custom-dropdown-wrapper").style.right = "0px";
      document.getElementById("triangle").style.marginLeft = "190px";
    } else if (this.type == "sort") {
      if (window.innerWidth <= 768 || document.body.clientWidth <= 768) {
        document.getElementById("custom-dropdown-wrapper").style.left = "0px";
        document.getElementById("triangle").style.marginLeft = "15px";
      } else {
        document.getElementById("custom-dropdown-wrapper").style.right = "25px";
        document.getElementById("triangle").style.marginLeft = "200px";
      }
    }
  }
}
