import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { GlobalComponentsModule } from "./global-components/global-components.module";
import { HomeFeatureModule } from "./home/home.module";
//
import { ShareModule, SharerMethod } from "ngx-sharebuttons";
//
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HomeFeatureModule,
    GlobalComponentsModule,
    ShareModule.withConfig({
      sharerMethod: SharerMethod.Window,
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
